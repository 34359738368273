import { createEvent } from 'effector';

import { Document } from 'models/Document';
import { DocumentStructure } from 'models/Questionnaire';

export const getDocuments = createEvent<{ assessmentId: number, abortController?: AbortController }>();

export const setHasError = createEvent<boolean>();

export const setIsDocumentLoading = createEvent<boolean>();

export const setIsPdfCreating = createEvent<boolean>();

export const setDocument = createEvent<Document | null>();

export const setDocumentTitle = createEvent<string>();

export const setMainOrder = createEvent<string[]>();

export const setDocumentStructure = createEvent<DocumentStructure>();

export const setHasSuccessMessage = createEvent<boolean>();
