import { Block, Blocks, BlockType, TextBlock } from 'models/Block';
import { variableClassnames } from 'features/Form/TextEditor/constants';
import { SingleVariableType } from 'models/Document/Document';
import { SingleVariable } from 'models/Questionnaire/Question';

import { deepCopy } from '../deepCopy';
import { getKey } from '../generateKey';

export const insertValueIntoVariable = (
    text: string, 
    variables: { [target: string]: string }, 
) => {
    Object.entries(variables)
        .map(([variableKey, variableValue]) => {
            if (text.includes(variableKey)) {
                text = text.replaceAll(variableKey, variableValue);
            }
        });

    return text;
};

export const insertHtmlIntoVariable = (
    text: string, 
    variables: SingleVariableType, 
    getValue: (key: SingleVariable, value: string) => string,
) => {
    Object.entries(variables)
        .map(([variableKey, variableValue]) => {
            if (text.includes(variableKey)) {
                const value = getValue ? getValue(variableKey as SingleVariable, variableValue) : variableValue;

                text = text.replaceAll(variableKey, value); ;
            }
        });

    return text;
};

export const changeSingleVariablesInHtml = (
    htmlString: string,
    variables: SingleVariableType,
) => {
    const tempDomElement = document.createElement('div');
    tempDomElement.innerHTML = htmlString;
    const spanElementsWithVariables = tempDomElement.querySelectorAll('span.variable');

    const variablesClasses: Record<string, string> = Object.keys(variables)
        .reduce((accumulator, variable) => ({ ...accumulator, [variableClassnames[variable as SingleVariable]]: variable }), {});

    spanElementsWithVariables.forEach(element => {
        const classNames = element.classList;

        const varClassName = classNames[1];
        const variable = variablesClasses[varClassName];

        if (variable) {
            element.textContent = variables[variable as SingleVariable];
        }
    });

    return tempDomElement.innerHTML;
};

export const insertTextVariable = (value: TextBlock, replaceWith: string, target: string) => {
    const newValue = deepCopy(value);
    const text = newValue.text.htmlString;
    const result = text.replaceAll(target, replaceWith);

    newValue.text.htmlString = result;

    return newValue;
};

export const insertListOfTextVariables = (
    listVariables: { [key: string]: string }[],
    blocks: [string, Block][],
) => {
    const list: string[] = [];
    const newBlocks: Blocks = {};

    listVariables.forEach(variablesLine => {
        blocks.forEach(([_, block]) => {
            if (block.type === BlockType.Text) {
                const key = getKey();
                let newBlock = deepCopy(block);

                Object.entries(variablesLine).map(([key, value]) => {
                    newBlock = insertTextVariable(newBlock, value, key);
                });
                list.push(key);

                newBlocks[key] = newBlock;
            }
        });
    });

    return { newBlocks, list };
};

// eslint-disable-next-line max-len
export const getValue = (key: SingleVariable, value: string) => `<span class="variable ${variableClassnames[key]}" data-variable="true" data-tooltip="variable ${variableClassnames[key]}" contenteditable="false">${value}</span>`;

export const findVariablesAndReplace = (blocks: Blocks, singleVariables: SingleVariableType) => {
    const copyBlocks = deepCopy(blocks);

    Object.entries(copyBlocks).map(([id, block]) => {
        if (block.type === BlockType.Text) {
            const htmlString = insertHtmlIntoVariable(block.text.htmlString, singleVariables, getValue);

            copyBlocks[id] = {
                ...block,
                text: {
                    ...block.text,
                    htmlString,
                }
            };
        }
    });

    return copyBlocks;
};

export const findHtmlVariablesAndReplace = (blocks: Blocks, singleVariables: SingleVariableType) => {
    const copyBlocks = deepCopy(blocks);

    Object.entries(copyBlocks).map(([id, block]) => {
        if (block.type === BlockType.Text) {
            const htmlString = changeSingleVariablesInHtml(block.text.htmlString, singleVariables);

            copyBlocks[id] = {
                ...block,
                text: {
                    ...block.text,
                    htmlString,
                }
            };
        }
    });

    return copyBlocks;
};
