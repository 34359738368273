import { AxiosError } from 'axios';

import {
    processSuccessfulResponseResult,
    processUnsuccessfulResponseResult,
    ResponseApiResult,
} from 'shared/utils/apiResult';
import { FileResult } from 'models/File';

import { FILE_URL } from './constants';
import { axiosInstance } from '../axiosInstance';


export const ImageApi = {
    async uploadImage(
        formData: FormData
    ): Promise<ResponseApiResult<FileResult | null>> {
        const ERR_DESC = 'Failed to upload image';

        try {
            const response = await axiosInstance.post(FILE_URL, formData);

            const result = processSuccessfulResponseResult(response, (responseData: FileResult) => responseData);

            return result;
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },
    async getImage(fileName: string): Promise<ResponseApiResult<ArrayBuffer | null>> {
        const ERR_DESC = 'Failed to get image';

        try {
            const response = await axiosInstance.get(`${FILE_URL}/download?fileName=${fileName}`, {
                responseType: 'arraybuffer'
            });

            const result = processSuccessfulResponseResult(response, (responseData: ArrayBuffer) => responseData);

            return result;
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },
};
