import { createEffect } from 'effector';

import { UserAPI } from 'api/user/api';
import { User } from 'models/User/User';

import { initialUser } from './constants';

export const fetchUser = createEffect(async (): Promise<User> => {
    const result = await UserAPI.getUserInfo();

    return result.match(
        result => result ?? initialUser,
        error_ => initialUser,
    );
});

export const $isFetchingUser = fetchUser.pending;
