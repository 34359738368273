import { combine, createStore } from 'effector';

import { Document, Variables } from 'models/Document';
import { Size } from 'models/Assessment';
import { DocumentStructure } from 'models/Questionnaire';
import { SingleVariableType } from 'models/Document/Document';

import { $areDocumentsLoading, $isDocumentFetching, $isUpdatingDocument } from './effects';

export const $documentStructure = createStore<DocumentStructure | null>(null);

export const $documents = createStore<Document[]>([]);

export const $hasError = createStore<boolean>(false);

export const $documentVariables = createStore<Variables>({
    singleTargets: {} as SingleVariableType,
    arrayTargets: {},
    chartTargets: {},
    intermediateVars: {},
    arrayIds: {},
});

export const $annualVisits = createStore<string>('');

export const $annualVisitsSize = createStore<Size>(Size.Low);

export const $document = createStore<Document | null>(null);

export const $documentTitle = createStore('');

export const $mainOrder = createStore<string[]>([]);

export const $isDocumentCreating = createStore(false);

export const $isPdfCreating = createStore(false);

export const $hasSuccessMessage = createStore(false);

export const $isUpdatingDocumentSuccess = combine(
    $isUpdatingDocument, $hasSuccessMessage,
    (isUpdatingDocument, hasSuccessMessage) => hasSuccessMessage && isUpdatingDocument
);

export const $isDocumentLoading = combine(
    $isDocumentCreating, $isDocumentFetching,
    (first, second) => first || second
);

export const $documentState = combine({
    document: $document,
    isDocumentLoading: $isDocumentLoading,
});

export const $documentsState = combine({
    documents: $documents,
    areDocumentsLoading: $areDocumentsLoading,
});
