import {
    RadioGroup as MuiRadioGroup,
    RadioGroupProps as MuiRadioGroupProps,
    FormControl,
    FormLabel,
} from '@mui/material';
import classNames from 'classnames';

import { RadioGroupLayout } from './RadioGroupLayout';
import { RadioGroupOption } from './interfaces';
import { Radio } from './Radio';

import './RadioGroup.scss';

export interface RadioGroupProps extends MuiRadioGroupProps {
    className?: string;
    label?: string | React.ReactNode;
    value: boolean | number | string | undefined;
    labelClassName?: string;
    groupClassName?: string;
    layout?: RadioGroupLayout;
    options: ReadonlyArray<RadioGroupOption>;
    disabled?: boolean;
}

export function RadioGroup({
    className,
    label,
    value,
    labelClassName,
    groupClassName,
    options,
    layout = RadioGroupLayout.Vertical,
    disabled = false,
    ...props
}: RadioGroupProps) {
    return (
        <FormControl
            className={className}
            component='fieldset'
            disabled={disabled}
        >
            <FormLabel
                className={labelClassName}
                component='legend'
            >
                {label}
            </FormLabel>
            <MuiRadioGroup
                value={value}
                className={classNames(
                    groupClassName,
                    'common-radio-group',
                    { 'common-radio-group_horizontal': layout === RadioGroupLayout.Horizontal },
                )}
                {...props}
            >
                {options.map(({ label, value: optionValue, disabled, className, selectedClassName }) => (
                    <Radio
                        key={`${label}-option`}
                        value={optionValue}
                        label={label}
                        disabled={disabled}
                        className={classNames(
                            className,
                            optionValue === value && selectedClassName,
                        )}
                    />
                ))}
            </MuiRadioGroup>
        </FormControl>
    );
}
