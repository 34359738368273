import { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { useStore } from 'effector-react';

import { getBase64FromFile, arrayBufferToBlob } from 'shared/utils/file';
import { getCurrentTimestamp } from 'shared/utils/date/getCurrentTimestamp';
import { Blocks, BlockType } from 'models/Block';
import { setIsPdfCreating } from 'store/document';
import { getImage } from 'store/image';
import { $documentTitle } from 'store/document/store';
import { deepCopy } from 'shared/utils/deepCopy';

import { createPdfObject } from './createPdfObject/createPdfObject';

type PdfMakeType = typeof import('pdfmake/build/pdfmake');

interface PdfContextPayload {
    pdfMake?: PdfMakeType;
    createPdf?: (values: Blocks, order: string[], pdfMake?: PdfMakeType) => void;
    isLoaded?: boolean;
}

const PdfContext = createContext<PdfContextPayload>({});

const getAsyncPdfModules = async () => await import('pdfmake/build/pdfmake');

export const usePdfLib = () => useContext(PdfContext) as Required<PdfContextPayload>;

export const PdfProvider = ({ children }: { children: ReactNode }) => {
    const pdfMakeRef = useRef<PdfMakeType>();

    const title = useStore($documentTitle);

    useEffect(() => {
        void getAsyncPdfModules().then(pdfMake => {
            const origin = window.location.origin;

            window.pdfMake.fonts = {
                Calibri: {
                    normal: `${origin}/fonts/Calibri_Regular.ttf`,
                    bold: `${origin}/fonts/Calibri_Bold.ttf`,
                    italics: `${origin}/fonts/Calibri_Italic.ttf`,
                    bolditalics: `${origin}/fonts/Calibri_Bold_Italic.ttf`,
                },
                'Brush Script MT': {
                    italics: `${origin}/fonts/BrushScriptMT-Italic.otf`,
                },
                Merriweather: {
                    normal: `${origin}/fonts/Merriweather-Regular.ttf`,
                    bold: `${origin}/fonts/Merriweather-Bold.ttf`,
                }
            };

            pdfMakeRef.current = pdfMake;
        });
    }, []);

    const createPdf = useCallback(async (values: Blocks, order: string[]) => {
        const values_ = deepCopy(values);

        const addBase64 = async (values: Blocks) => {
            await Promise.all(Object.values(values).map(async element => {
                if (element.type === BlockType.Image && element.image.src) {
                    const data = await getImage(element.image.src);

                    if (data) {
                        const blob = arrayBufferToBlob(data, 'image/jpeg');
                        const base64 = await getBase64FromFile(blob);

                        element.image.base64 = base64;
                    }
                }
            }));
        };

        if (pdfMakeRef.current) {
            setIsPdfCreating(true);

            await addBase64(values_);

            const data = createPdfObject(values_, order);
            const pdfDocGenerator = pdfMakeRef.current.createPdf(data);

            pdfDocGenerator.download(`${title} (${getCurrentTimestamp()})`);
            setTimeout(() => setIsPdfCreating(false), 500);
        }
    }, [title]);

    const value = useMemo(() => ({
        pdfMake: pdfMakeRef.current,
        createPdf,
    }), [createPdf]);

    return (
        <PdfContext.Provider value={value}>
            {children}
        </PdfContext.Provider>
    );
};
