import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import cn from 'classnames';

import { SelectVariant } from './SelectVariant';

export type SelectItem = {
    value: string | number;
    displayName: string;
}

export interface SelectProps extends Omit<MuiSelectProps, 'onChange'> {
    labelClassName?: string;
    items: SelectItem[];
    onChange: (value: string) => void;
}

export function Select({
    native = false,
    items,
    className,
    labelClassName,
    fullWidth,
    variant = SelectVariant.Outlined,
    id,
    label,
    labelId,
    onChange,
    ...restProps
}: SelectProps) {
    return (
        <FormControl fullWidth={fullWidth} variant={variant} className={cn('custom-select-control', className)}>
            <InputLabel
                id={labelId}
                htmlFor={native ? id : undefined}
                className={cn('custom-select-control__label', labelClassName)}
                shrink
            >
                {label}
            </InputLabel>
            <MuiSelect
                id={id}
                labelId={!native ? labelId : undefined}
                native={native}
                onChange={event => {
                    const value = event?.target.value as string;
                    onChange(value);
                }}
                label={label}
                notched
                {...restProps}
            >
                {native
                    ? items.map(i => <option key={i.value} value={i.value}>{i.displayName}</option>)
                    : items.map(i => <MenuItem key={i.value} value={i.value}>{i.displayName}</MenuItem>)}
            </MuiSelect>
        </FormControl>
    );
}
