import { Outlet } from 'react-router-dom';

import { FeedbackButton } from 'widgets/Feedback/GlobalFeedbackButton/FeedbackButton';

export const Container = () => {
    return (
        <>
            <Outlet />
            <FeedbackButton />
        </>
    );
};
