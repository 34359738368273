import { ReactNotifications } from 'react-notifications-component';
import { useGate } from 'effector-react';

import { ApplicationInsightsGate } from 'store/appInsights/effects';

import { AppRouter, ThemeProvider, PdfProvider } from './providers';
import { appInsights } from './providers/AppInsights';
import { ErrorBoundary } from './providers/ErrorBoundary';

import 'react-notifications-component/dist/theme.css';
import './App.scss';

export const App = () => {
    useGate(ApplicationInsightsGate, { appInsights });

    return (
        <>
            <ReactNotifications />
            <ThemeProvider>
                <ErrorBoundary>
                    <PdfProvider>
                        <div className='dynamic-pdf'>
                            <AppRouter />
                        </div>
                    </PdfProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </>
    );
};
