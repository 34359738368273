import { SingleVariable } from 'models/Questionnaire/Question';

export const defaultFontSize = '17px';
export const colors = ['#000000', '#a6a6a6', 'red', '#ed833a', '#ffc000', 'green', 'blue'];
export const fontSizes = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 28, 32, 36, 48, 56, 72];
export const defaultVariables = ['**Site Name**', '**Date of Assessment**', '**Day of Creating Document**'];
export const fonts = ['Calibri', 'Merriweather'];

export const variableClassnames = {
    [SingleVariable.SiteName]: 'siteName',
    [SingleVariable.DateOfAssessment]: 'dateOfAssessment',
    [SingleVariable.DayOfCreatingDocument]: 'dayOfCreatingDocument',
};

export const formats = [
    'bold', 'italic', 'underline', 
    'link', 
    'script', 
    'color', 
    'size', 'increaseFontSize', 'decreaseFontSize',
    'align',
    'variable',
    'font',
    'addBreakPage',
];
