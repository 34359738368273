import cn from 'classnames';

import { ImageProperties } from 'models/Image';

import './Image.scss';

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    className?: string;
    url: string;
    image: ImageProperties;
}

export const Image = ({
    className,
    url,
    image,
    onClick,
    ...restProps
}: ImageProps) => {

    return (
        <img
            {...restProps}
            className={cn('image', className, `image_${image.alignment ?? 'left'}`)}
            src={url}
            style={{ maxWidth: `${image.width}px`, maxHeight: `${image.height}px` }}
            width='100%'
            onClick={onClick}
        />
    );
};
