import {
    Tooltip as MuiTooltip,
    TooltipProps as MuiTooltipProps,
} from '@mui/material';

interface TooltipProps extends MuiTooltipProps {
    title: string;
}

export const Tooltip = ({
    title,
    children,
    ...restProps
}: TooltipProps) => {
    return (
        <MuiTooltip
            {...restProps}
            title={title}
            placement='top'
            arrow
        >
            {children}
        </MuiTooltip>
    );
};
