import { Decoration, Margins } from 'pdfmake/interfaces';

import { headingsTocStyles, HeadingType } from 'models/Heading';

export const pointsInPixel = 0.75292857248934;
export const orangeColor = '#ed833a';

export const tocItemsStyles = {
    [headingsTocStyles[HeadingType.Heading1]]: {
        margin: [0, 2] as Margins,
    },
    [headingsTocStyles[HeadingType.Heading2]]: {
        margin: [20, 2, 0, 2] as Margins,
    },
    [headingsTocStyles[HeadingType.Heading3]]: {
        margin: [40, 2, 0, 2] as Margins,
    },
};

export const styles = {
    heading: {
        bold: true,
        font: 'Merriweather',
    },
    [HeadingType.Heading1]: {
        fontSize: 15,
        margin: [0, 5] as Margins,
        color: orangeColor,
    },
    [HeadingType.Heading2]: {
        fontSize: 15,
        margin: [0, 5] as Margins,
    },
    [HeadingType.Heading3]: {
        fontSize: 14,
        margin: [0, 5, 0, 0] as Margins,
    },
    indent: {
        margin: [0, 20] as Margins,
    },
    paragraph: {
        margin: [0, 5] as Margins,
        lineHeight: 1.3,
    },
    manuscript: {
        font: 'Brush Script MT',
        italics: true,
        fontSize: 28,
    },
    image: {
        margin: [0, 0, 0, 14] as Margins,
    },
    link: {
        color: '#0563c1',
        decoration: 'underline' as Decoration,
    },
    list: {
        margin: [20, 5, 0, 5] as Margins,
    },
    nestedList: {
        margin: [20, 0, 0, 0] as Margins,
    },
    listItem: {
        margin: 0,
    },
    tableOfContents: {
        font: 'Merriweather',
        fontSize: 15,
        bold: true,
        color: orangeColor,
    },
    ...tocItemsStyles,
};
