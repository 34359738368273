import { DynamicCellLayout, PageBreak, StyleReference } from 'pdfmake/interfaces';

import { Columns } from 'models/Columns';
import { Table } from 'models/Table';
import { Chart } from 'models/Chart';
import { TableOfContents } from 'models/TableOfContents';
import { Formatting } from 'models/Text';
import { ImageProperties } from 'models/Image';

export enum BlockType {
    Indent = 'indent',
    Text = 'text',
    List = 'list',
    Image = 'image',
    Columns = 'columns',
    Table = 'table',
    Chart = 'chart',
    TableOfContents = 'tableOfContents',
    Heading = 'heading',
    LibraryComponent = 'libraryComponent',
    Pointer = 'pointer'
}

export enum BlockName {
    Indent = 'Indent',
    Text = 'Text Block',
    List = 'List',
    Image = 'Image',
    Columns = 'Columns',
    Table = 'Table',
    TableOfContents = 'Table Of Contents',
    LibraryComponent = 'Library Component',
}

interface BlockBase {
    style?: StyleReference;
    isManuallyAdded?: boolean;
    stepNumber?: number;
    smartName?: string;
}

export interface PointerBlock extends BlockBase {
    type: BlockType.Pointer;
}

export interface IndentBlock extends BlockBase {
    type: BlockType.Indent;
    text: string;
}

export interface TextBlock extends BlockBase {
    type: BlockType.Text;
    text: {
        htmlString: string;
        formatting?: Formatting[];
    };
    pageBreak?: PageBreak;
}

export interface HeadingBlock extends BlockBase {
    type: BlockType.Heading;
    text: string;
    tocStyle: string;
    pageBreak?: PageBreak;
}

export interface ListBlock extends BlockBase {
    type: BlockType.List;
    list: string[];
    initialList?: string[];
    isOrdered?: boolean;
}

export interface ColumnsBlock extends BlockBase {
    type: BlockType.Columns;
    table: Columns;
}

export interface TableBlock extends BlockBase {
    type: BlockType.Table;
    table: Table;
    fillColor?: string | DynamicCellLayout<string>;
}

export interface ImageBlock extends BlockBase {
    type: BlockType.Image;
    image: ImageProperties;
}

export interface ChartBlock extends BlockBase {
    type: BlockType.Chart;
    chart: Chart;
}

export interface TableOfContentsBlock extends BlockBase {
    type: BlockType.TableOfContents;
    toc: TableOfContents;
}

export interface LibraryComponentBlock extends BlockBase {
    type: BlockType.LibraryComponent;
    order: string[];
    code?: string;
}

export type Block =
    PointerBlock |  
    IndentBlock |
    TextBlock |
    HeadingBlock |
    ListBlock |
    ColumnsBlock |
    TableBlock |
    ImageBlock |
    ChartBlock |
    TableOfContentsBlock |
    LibraryComponentBlock;

export interface Blocks { [blockId: string]: Block }

export interface Content { blocks: Blocks, order: string[] }

export enum Pointer {
    PriorityRecommendation = 'priorityRecommendationsOrder',
}
