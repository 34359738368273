import { sample, forward } from 'effector';

import { findVariablesAndReplace } from 'shared/utils/document/insertVariables';
import { deepCopy } from 'shared/utils/deepCopy';
import { getSize } from 'features/EditPage/ChartComponent/utils';
import { $userAnswers } from 'store/questions/store';

import { fetchDocumentById, fetchDocuments } from './effects';
import {
    setDocumentStructure,
    setDocument,
    setHasError,
    setIsDocumentLoading,
    setMainOrder,
    setIsPdfCreating,
    getDocuments,
    setHasSuccessMessage,
    setDocumentTitle,
} from './events';
import {
    $annualVisits,
    $annualVisitsSize,
    $document,
    $documents,
    $documentStructure,
    $documentTitle,
    $documentVariables,
    $hasError,
    $hasSuccessMessage,
    $isDocumentCreating,
    $isPdfCreating,
    $mainOrder,
} from './store';

sample({
    clock: getDocuments,
    target: fetchDocuments,
});

sample({
    //@ts-ignore
    clock: fetchDocuments.doneData,
    filter: (data) => !!(data || data === null),
    target: $documents,
});

sample({
    clock: setHasError,
    target: $hasError,
});

forward({
    from: setDocument,
    to: $document,
});

forward({
    from: setHasSuccessMessage,
    to: $hasSuccessMessage,
});

forward({
    from: setDocumentTitle,
    to: $documentTitle,
});

forward({
    from: setDocumentStructure,
    to: $documentStructure,
});

forward({
    from: setMainOrder,
    to: $mainOrder,
});

sample({
    clock: $documentVariables,
    fn: (documentVariables) => documentVariables.chartTargets['Annual-Visits'] ?? '',
    target: $annualVisits,
});

sample({
    clock: $annualVisits,
    fn: annualVisits => getSize(+annualVisits.replace(/\,/g, '')),
    target: $annualVisitsSize,
});

sample({
    clock: $annualVisitsSize,
    source: $documentVariables,
    fn: (documentVariables, annualVisitsSize) => ({
        ...documentVariables,
        intermediateVars: { ...documentVariables.intermediateVars, annualVisitsSize },
    }),
    target: $documentVariables,
});

forward({
    from: setIsDocumentLoading,
    to: $isDocumentCreating,
});

sample({
    //@ts-ignore
    clock: fetchDocumentById.doneData,
    filter: (data) => !!(data || data === null),
    target: $document,
});

sample({
    clock: $document,
    fn: document => document?.content?.order ?? [],
    target: $mainOrder,
});

sample({
    clock: $document,
    filter: document => !!document?.content?.variables,
    fn: document => deepCopy(document!.content?.variables),
    target: $documentVariables,
});

sample({
    clock: $document,
    filter: document => !!document?.content.answers,
    fn: document => document!.content.answers!,
    target: $userAnswers,
});

forward({
    from: setIsPdfCreating,
    to: $isPdfCreating,
});

sample({
    clock: [$document, $documentVariables],
    source: {
        document: $document, 
        documentVariables: $documentVariables,
    },
    filter: ({ document, documentVariables }) => {
        const hasVariables = !!Object.keys(documentVariables?.singleTargets).length;
        const hasDocument = !!document;

        return hasVariables && hasDocument;
    },
    fn: ({ document, documentVariables }) => {
        if (!document) return null;

        const singleVariables = documentVariables?.singleTargets;
        const blocks = findVariablesAndReplace(document?.content.blocks, singleVariables);

        document.content.blocks = blocks;

        return document;
    },
    target: $document,
});
