import { createEffect } from 'effector';

import { ImageApi } from 'api/image/api';
import { showErrorNotification } from 'shared/utils/notification';

export const uploadImage = createEffect(async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const result = await ImageApi.uploadImage(formData);

    return result.match(
        data => data,
        error => {
            showErrorNotification(error);

            return null;
        },
    );
});

export const $isImageLoading = uploadImage.pending;

export const getImage = createEffect(async (fileName: string) => {
    const result = await ImageApi.getImage(fileName);

    return result.match(
        data => data,
        error => {
            showErrorNotification(error);

            return null;
        },
    );
});
