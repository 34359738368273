import { ErrorDescription } from './interfaces';

export class ResponseApiResult<TData> {
    public static createSuccessful<TData>(data: TData, aborted: boolean) {
        return new ResponseApiResult(true, data, aborted ? { message: 'aborted', canceled: true } : null);
    }

    public static createUnsuccessful(error: ErrorDescription) {
        return new ResponseApiResult(false, null, error);
    }

    private _error: ErrorDescription | null;
    private _data: TData;

    private constructor(
        public readonly isSuccessful: boolean,
        data: TData,
        error: ErrorDescription | null
    ) {
        this._data = data;
        this._error = error;
    }

    public get error() {
        if (this.isSuccessful) {
            throw new Error('Cannot access error when result is successful.');
        }

        return this._error;
    }

    public get data() {
        if (!this.isSuccessful) {
            throw new Error('Cannot access data when result is unsuccessful.');
        }

        return this._data;
    }

    public match<T>(
        onSuccess: (data: TData) => T,
        onError: (error: ErrorDescription | null) => T,
    ): T {
        return this.isSuccessful ? onSuccess(this.data) : onError(this.error);
    }
}
