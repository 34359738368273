import { useState, PropsWithChildren, RefObject } from 'react';
import { IconButton, Popper as MuiPopper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import './Popper.scss';

const properties = [
    {
        name: 'flip',
        enabled: true,
        options: {
            altBoundary: true,
            rootBoundary: 'document',
            padding: 8,
        },
    },
    {
        name: 'preventOverflow',
        enabled: true,
        options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: 'document',
            padding: 8,
        },
    },
];

interface PopperProps {
    open: boolean;
    id: string;
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}

export const Popper = ({
    open,
    handleClose,
    id,
    anchorEl,
    children,
    ...restProps
}: PropsWithChildren<PopperProps>) => {
    const [arrowRef, setArrowRef] = useState(null);

    return (
        <MuiPopper
            {...restProps}
            className='popper'
            placement='top-end'
            open={open}
            id={id}
            anchorEl={anchorEl}
            modifiers={[
                ...properties,
                {
                    name: 'arrow',
                    enabled: true,
                    options: {
                        element: arrowRef,
                }},
            ]}
        >
            <>
                <IconButton
                    className='popper__close-button'
                    onClick={handleClose}
                    children={<CloseIcon />}
                />
                {children}
                <div className='popper__arrow' ref={setArrowRef as unknown as RefObject<HTMLDivElement>} />
            </>
        </MuiPopper>
    );
};
