import {
    Content, ContentTable, TDocumentDefinitions, Node, StyleReference,
    ContentText, ContentUnorderedList, ContentOrderedList
} from 'pdfmake/interfaces';

import { Block, Blocks, BlockType } from 'models/Block';
import { Formatting } from 'models/Text';
import { Alignment } from 'models/Image';
import { deepCopy } from 'shared/utils/deepCopy';

import { orangeColor, pointsInPixel, styles, tocItemsStyles } from './constants';
import { firstPageBackground, background } from './backgrounds';

const convertPixelsToPoints = (pixelsNumber?: number) => pixelsNumber && pixelsNumber * pointsInPixel;

const getColumnLeftPadding = (i: number) => i ? 7.5 : 0;

const getColumnRightPadding = (i: number, node: ContentTable) => i === node.table.widths!.length - 1 ? 0 : 7.5;

const getTextStyleProperty = (attribute: string, value: any) => {
    switch (attribute) {
        case 'italic':
            return { italics: value };
        case 'underline':
            return { decoration: 'underline' };
        case 'script':
            return { [value === 'super' ? 'sup' : 'sub']: true };
        case 'align':
            return { alignment: value };
        case 'link':
            return { link: value, style: 'link' };
        case 'size':
            return { fontSize: convertPixelsToPoints(+value.replace('px', '')) };
        default:
            return { [attribute]: value };
    }
};

const getTextStyles = (styles: object, initialStyles?: object) => {
    return Object.entries(styles).reduce((obj, [attribute, value]) => ({
        ...obj, ...getTextStyleProperty(attribute, value),
    }), initialStyles);
};

const getTextFragments = (fragments: Formatting[], commonStyles?: object) => {
    const initialStyles = { ...commonStyles && getTextStyles(commonStyles) };

    return fragments.map(({ insert, attributes }) => {
        return {
            text: insert!,
            ...(
                attributes
                    ? getTextStyles(attributes, initialStyles)
                    : initialStyles
            ),
        };
    });
};

const getText = (formatting: Formatting[]) => {
    const lines: Formatting[][] = [];
    const linesStyles: (object | undefined)[] = [];
    let startIndex = 0;

    deepCopy(formatting)
        .forEach(({ insert, attributes }, i, array) => {
            if (insert === '\n') {
                lines.push(array.slice(startIndex, i + 1));
                linesStyles.push(attributes);

                startIndex = i + 1;
            } else if (insert!.includes('\n')) {
                let arr: Formatting[] = [];
                const fragmentsFromDifferentLines = insert!.split('\n');

                if (startIndex !== i) {
                    arr = array.slice(startIndex, i);
                }

                if (fragmentsFromDifferentLines[fragmentsFromDifferentLines.length - 1]) {
                    array[i].insert = fragmentsFromDifferentLines.pop();
                    startIndex = i;
                } else {
                    fragmentsFromDifferentLines.pop();
                    startIndex = i + 1;
                }

                fragmentsFromDifferentLines.map(line => line + '\n').forEach((line, i) => {
                    lines.push(i ? [{ insert: line }] : arr.concat({ insert: line }));
                    linesStyles.push({});
                });
            }
        });

    return lines.flatMap((lineFragments, i) => getTextFragments(lineFragments, linesStyles[i]));
};

const getList = (list: (ContentUnorderedList | ContentOrderedList | ContentText)[]) => {
    const transformedList = [];
    let i = 0;

    while (i < list.length) {
        const currentItem = list[i];
        const nextItem = list[i + 1];

        if (!nextItem || nextItem.text) {
            transformedList.push(currentItem);
            i++;
        } else {
            transformedList.push([currentItem, nextItem]);
            i += 2;
        }
    }

    return transformedList;
};

const getBlockStyle = (initialStyle: string | string[], style: StyleReference | undefined) => {
    return Array.isArray(style)
        ? style.concat(initialStyle)
        : style
            ? Array.isArray(initialStyle)
                ? [style, ...initialStyle]
                : [style, initialStyle]
            : initialStyle;
};

const getBlockContent = (block: Block, values: Blocks, parentBlock?: Block): Content | undefined => {
    switch (block.type) {
        case BlockType.Indent: {
            return { text: block.text, style: 'indent' };
        }
        case BlockType.Text: {
            const { style, text: { htmlString, formatting }, pageBreak } = block;

            const isListItem = parentBlock?.type === BlockType.List;
            const initialStyle = isListItem ? ['listItem', 'paragraph'] : 'paragraph';

            return {
                text: formatting ? getText(formatting) : htmlString.replace(/<\/?p>/g, ''),
                style: getBlockStyle(initialStyle, style),
                pageBreak,
            };
        }
        case BlockType.Heading: {
            const { text, style, tocStyle, pageBreak } = block;
            const initialStyle = 'heading';

            const headingText = text
                ? {
                    text,
                    style: getBlockStyle(initialStyle, style),
                    tocItem: true,
                    tocMargin: tocItemsStyles[tocStyle].margin,
                    pageBreak,
                }
                : '';

            return headingText;
        }
        case BlockType.List: {
            const isParentList = parentBlock?.type === BlockType.List;

            const style = isParentList ? 'nestedList' : 'list';

            const list = block.list.map(
                itemKey => getBlockContent(values[itemKey], values, block)
            ) as (ContentUnorderedList | ContentOrderedList | ContentText)[];

            const transformedList = getList(list);

            return block.isOrdered
                ? {
                    ol: transformedList,
                    style,
                }
                : {
                    ul: transformedList,
                    type: isParentList ? 'circle' : 'disc',
                    style,
                };
        }
        case BlockType.Image: {
            const { base64, width, height, alignment } = block.image;

            const image = base64
                ? {
                    image: base64,
                    style: 'image',
                    width: convertPixelsToPoints(width),
                    height: convertPixelsToPoints(height),
                    alignment: alignment ?? Alignment.Left,
                }
                : '';

            return image;
        }
        case BlockType.Columns: {
            const { widths, body } = block.table;

            return {
                table: {
                    widths: widths.map(width => `${width}%`),
                    body: [body.map(column => column.map(blockKey => getBlockContent(values[blockKey], values)))],
                },
                layout: {
                    defaultBorder: false,
                    paddingLeft: getColumnLeftPadding,
                    paddingRight: getColumnRightPadding,
                },
            };
        }
        case BlockType.Table: {
            const { widths, body } = block.table;

            return {
                table: {
                    widths: widths.map(width => `${width}%`),
                    body: body.map(row => row.map(cell => cell.map(blockKey => getBlockContent(values[blockKey], values)))),
                },
                layout: {
                    paddingLeft: () => 7.5,
                    paddingRight: () => 7.5,
                    hLineWidth: () => 0.5,
                    vLineWidth: () => 0.5,
                    fillColor: rowIndex => !rowIndex ? orangeColor : null,
                    hLineColor: '#FFAD66',
                    vLineColor: '#FFAD66',
                },
            };
        }
        case BlockType.Chart: {
            const { svg, width, height } = block.chart;

            const chart = svg
                ? {
                    svg,
                    style: 'image',
                    width: convertPixelsToPoints(width),
                    height: convertPixelsToPoints(height),
                }
                : '';

            return chart;
        }
        case BlockType.TableOfContents: {
            return {
                toc: { title: { text: block.toc.title, style: ['tableOfContents', 'paragraph'] } },
                pageBreak: 'after',
            };
        }
        case BlockType.Pointer: {
            return;
        }
        default: {
            throw new Error('The block has an unexpected type');
        }
    }
};

export const createPdfObject = (values: Blocks, order: string[]) => {
    const content: Content[] = [];

    const getDocumentContent = (order: string[]) => {
        order.forEach(blockKey => {
            const block = values[blockKey];

            if (block.type === BlockType.LibraryComponent) {
                getDocumentContent(block.order);
            } else {
                const contentBlock = getBlockContent(block, values);

                contentBlock && content.push(contentBlock);
            }            
        });
    };

    getDocumentContent(order);

    const initial: TDocumentDefinitions = {
        content,
        styles,
        defaultStyle: {
            font: 'Calibri',
            fontSize: 12,
            alignment: Alignment.Left,
            lineHeight: 1.1,
        },
        pageMargins: [65, 60, 40, 95],
        pageSize: 'LETTER',
        background: (currentPage, pageSize) => ({
            image: currentPage === 1 ? firstPageBackground : background,
            width: pageSize.width,
            height: pageSize.height,
            opacity: currentPage === 1 ? 1 : 0.6,
        }),
        pageBreakBefore: (currentNode: Node) => {
            const followingNodeStyle = currentNode.style;

            return followingNodeStyle === 'tableOfContents' ||
                Array.isArray(followingNodeStyle) && followingNodeStyle?.includes('tableOfContents');
        },
        footer: currentPage =>
            currentPage !== 1
                ? ({
                    columns: [{
                        text: currentPage.toString(),
                        alignment: Alignment.Right,
                        marginRight: 55,
                        marginTop: 55,
                    }]
                })
                : null,
    };

    return initial;
};
