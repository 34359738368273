import { forward } from 'effector';

import { uploadImage } from './effects';
import { $image } from './store';


forward({
    from: uploadImage.doneData,
    to: $image,
});
