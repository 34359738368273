import { ErrorOutline } from '@mui/icons-material';

import './NotFound.scss';

export function NotFound() {
    return (
        <div className='not-found-page'>
            <div className='not-found-page__content'>
                <ErrorOutline className='not-found-page__icon' />
                <span className='not-found-page__text'>Incorrect page url</span>
            </div>
        </div>
    );
}
