import { AxiosError, AxiosResponse } from 'axios';

import { BAD_REQUEST_STATUS_CODE, INTERNAL_SERVER_ERROR_STATUS_CODE, NOT_FOUND_STATUS_CODE } from 'shared/constants/httpStatusCodes';
import { INTERNAL_SERVER_ERROR_MESSAGE, NOT_FOUND_ERROR_MESSAGE } from 'shared/constants/httpStatusMessages';

import { ResponseApiResult } from './Result';

export function processSuccessfulResponseResult<ResponseDataType, ResultDataType>(
    response: AxiosResponse,
    getResult: (responseData: ResponseDataType) => ResultDataType,
    aborted = false,
): ResponseApiResult<ResultDataType> {
    const result = getResult(response.data as ResponseDataType);

    return ResponseApiResult.createSuccessful(result, aborted);
}

export function processUnsuccessfulResponseResult(error: AxiosError, errorDescription: string): ResponseApiResult<null> {
    const CANCELED_ERROR_CODE = 'ERR_CANCELED';

    const status = error.response?.status;
    const statusText = error.response?.statusText ?? '';
    let errorMessage = '';

    switch (status) {
        case BAD_REQUEST_STATUS_CODE:
            errorMessage = statusText;
            break;
        case NOT_FOUND_STATUS_CODE:
            errorMessage = NOT_FOUND_ERROR_MESSAGE;
            break;
        case INTERNAL_SERVER_ERROR_STATUS_CODE:
            errorMessage = INTERNAL_SERVER_ERROR_MESSAGE;
            break;
        default:
            errorMessage = error.message;
    }

    return ResponseApiResult.createUnsuccessful({
        message: `${errorDescription}: ${errorMessage}`,
        canceled: error.code === CANCELED_ERROR_CODE,
    });
}
