export enum Route {
    AssessmentList = '',
    Templates = 'templates',
    Template = 'template/:id',
    Library = 'library',
    Questionnaire = 'questionnaire/:assessmentId',
    Init = 'init/:assessmentId',
    Assessment = 'assessment/:id',
    Component = 'component',
    ComponentId = 'component/:id',
    NotFound = '*',
};

export enum LogInRoute {
    OktaSignIn = 'oktaSigninCallback',
};
