import { AxiosError } from 'axios';

import { APIDocument, Document } from 'models/Document';
import {
    processSuccessfulResponseResult,
    processUnsuccessfulResponseResult,
    ResponseApiResult,
} from 'shared/utils/apiResult';

import {
    CREATE_DOCUMENT,
    GET_DOCUMENTS,
    GET_DOCUMENT_BY_ID,
    UPDATE_DOCUMENT,
    DELETE_DOCUMENT,
} from './constants';
import { axiosInstance } from '../axiosInstance';

export const DocumentsAPI = {
    async getDocuments(assessmentId: number, abortController?: AbortController): Promise<ResponseApiResult<Document[] | null>> {
        const ERR_DESC = 'Failed to get documents';

        try {
            const response = await axiosInstance.get(`${GET_DOCUMENTS}?assessmentId=${assessmentId}`, {
                signal: abortController?.signal,
            });

            const result = processSuccessfulResponseResult(response, (responseData: APIDocument[]) =>
                responseData.map(document => {
                    const parsed = JSON.parse(document.content);

                    return {
                        ...document,
                        content: parsed,
                    };
                })
            );

            return result;
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },

    async getDocumentById(
        id: string,
        abortController: AbortController,
    ): Promise<ResponseApiResult<Document | null>> {
        const ERR_DESC = 'Failed to get a document';

        try {
            const response = await axiosInstance.get(`${GET_DOCUMENT_BY_ID}?id=${id}`, {
                signal: abortController.signal,
            });

            const result = processSuccessfulResponseResult(response, (responseData: APIDocument) => ({
                ...responseData,
                content: JSON.parse(responseData.content),
            }));

            return result;
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },

    async updateDocument(
        document: Document,
        abortController: AbortController,
    ): Promise<ResponseApiResult<Document | null>> {
        const ERR_DESC = 'Failed to update a document';

        try {
            const response = await axiosInstance.post(UPDATE_DOCUMENT, {
                ...document,
                content: JSON.stringify(document.content),
                signal: abortController.signal,
            });

            const result = processSuccessfulResponseResult(response, (responseData: APIDocument) => ({
                ...responseData,
                content: JSON.parse(responseData.content),
            }));

            return result;
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },

    async createDocument(document: Document, siteName: string): Promise<ResponseApiResult<Document | null>> {
        const ERR_DESC = 'Failed to create a document';

        try {
            const response = await axiosInstance.post(`${CREATE_DOCUMENT}?siteName=${siteName}`, {
                ...document,
                content: JSON.stringify(document.content),
            });

            const result = processSuccessfulResponseResult(response, (responseData: APIDocument) => ({
                ...responseData,
                content: JSON.parse(responseData.content),
            }));

            return result;
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },

    async deleteDocument(id: number): Promise<ResponseApiResult<boolean | null>> {
        const ERR_DESC = 'Failed to delete a document';

        try {
            const response = await axiosInstance.delete(`${DELETE_DOCUMENT}?id=${id}`);

            return processSuccessfulResponseResult(response, () => true);
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },
};
