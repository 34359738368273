import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
} from '@mui/material';
import cn from 'classnames';

import './Modal.scss';

export interface ModalProps extends Omit<MuiDialogProps, 'open'> {
    isOpen: boolean;
}

export function Modal({ isOpen, PaperProps, ...restProps }: ModalProps) {
    return (
        <MuiDialog
            {...restProps}
            open={isOpen}
            PaperProps={{
                ...PaperProps,
                className: cn('dpdf-modal-paper', PaperProps?.className),
            }}
            classes={{
                root: 'dpdf-modal-root',
            }}
        />
    );
}
