import { Suspense } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import {
    RequireAuth,
    OktaAuth,
    AuthProvider,
    SignInRoute,
    Authentication,
} from '@medamerica/okta-react';

import { Loader } from 'shared/components';
import { NotFoundFallback } from 'pages/NotFoundFallback';

import { routes } from './routerConfig';
import { Container } from './Container/Container';
import { config } from './authConfig';

export const AppRouter = () => {
    const authentication = new Authentication(config);

    return (
        <AuthProvider
            authProvider={authentication}
            fallback={<Loader />}
        >
            <Router>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route element={<Container />}>
                            {routes.map(module => {
                                return (
                                    <Route
                                        key={module.path}
                                        path={module.path}
                                        element={<RequireAuth children={<module.component />} />}
                                    />
                                );
                            })}
                        </Route>
                        <Route path={SignInRoute.OktaSignInCallback} element={<OktaAuth />} />
                        <Route path="*" element={<NotFoundFallback />} />
                    </Routes>
                </Suspense>
            </Router>
        </AuthProvider>
    );
};
