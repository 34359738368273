/* eslint-disable max-len */
import { AnswerType, QuestionBlock, SectionName, YesNo } from 'models/Questionnaire';
import { QuestionBlockType, SingleVariable } from 'models/Questionnaire/Question';

const yesNoAnswers = [YesNo.Yes, YesNo.No];
const main = true;

export const establishedPracticeQuestionIds = ['11', '15', '19', '23', '27', '31', '35', '39', '43', '47', '51', '55', '63', '67', '71', '75', '79', '83', '87', '91', '95'];
export const includedPracticeQuestionIds = ['12', '16', '20', '24', '28', '32', '36', '40', '44', '48', '52', '56', '64', '68', '72', '76', '80', '84', '88', '92', '96'];
export const priorityQuestionIds = ['13', '17', '21', '25', '29', '33', '37', '41', '45', '49', '53', '57', '65', '69', '73', '77', '81', '85', '89', '93', '97'];
export const priorityNumberQuestionIds = ['14', '18', '22', '26', '30', '34', '38', '42', '46', '50', '54', '58', '66', '70', '74', '78', '82', '86', '90', '94', '98'];
export const prioritySelectionLimit = 4;
export const bestPracticeSelectionLimit = 5;

export const steps: QuestionBlock[] = [
    {
        id: 1,
        stepNumber: 1,
        questions: [
            { id: 1, text: 'Site Name', type: AnswerType.Input, target: SingleVariable.SiteName },
            { id: 2, text: 'Date of assessment', type: AnswerType.Date, target: SingleVariable.DateOfAssessment },
            { id: 2_1, text: 'Contract Start Date', type: AnswerType.Date, target: SingleVariable.DayOfCreatingDocument },
        ],
        sectionName: SectionName.GeneralInfo,
        type: QuestionBlockType.Various,
    }, {
        id: 2,
        stepNumber: 2,
        questions: [
            { id: 3, text: 'Name', type: AnswerType.Input, target: '**Name**' },
            { id: 4, text: 'Title', type: AnswerType.Input, target: '**Title**' },
        ],
        sectionName: SectionName.Participants,
        type: QuestionBlockType.List,
    }, {
        id: 3,
        stepNumber: 3,
        questions: [
            { id: 5, text: 'Annual Visits', type: AnswerType.Input, targetChart: 'Annual-Visits', mask: { thousandSeparator: true } },
            { id: 6, text: 'TTP', type: AnswerType.Input, targetChart: 'TTP-Site', mask: { thousandSeparator: true } },
            { id: 7, text: 'TAT-D', type: AnswerType.Input, targetChart: 'TAT-D-Site', mask: { thousandSeparator: true } },
            { id: 8, text: 'TAT-A', type: AnswerType.Input, targetChart: 'TAT-A-Site', mask: { thousandSeparator: true } },
            { id: 9, text: 'LWBS', type: AnswerType.Input, targetChart: 'LWBS-Site', mask: { thousandSeparator: true } },
        ],
        sectionName: SectionName.OperationalData,
        type: QuestionBlockType.Various,
    }, {
        id: 4,
        stepNumber: 4,
        questions: [
            { id: 11, text: 'RME Process', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 12, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 11: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 14 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 13, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 11: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C13' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 14, text: '', type: AnswerType.SelectBox, dependentField: { 11: YesNo.Yes, 13: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C13' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 15, text: 'Immediate Bedding', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 16, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 15: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 15 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 17, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 15: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C17' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 18, text: '', type: AnswerType.SelectBox, dependentField: { 15: YesNo.Yes, 17: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C17' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 19, text: 'Team Assessment', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 20, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 19: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 16 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 21, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 19: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C21' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 22, text: '', type: AnswerType.SelectBox, dependentField: { 19: YesNo.Yes, 21: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C21' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 23, text: 'Team Intake (RME & Triage)', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 24, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 23: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 17 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 25, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 23: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C25' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 26, text: '', type: AnswerType.SelectBox, dependentField: { 23: YesNo.Yes, 25: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C25' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 27, text: 'ESI 3 Practice', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 28, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 27: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 18 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 29, text: '', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 27: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C29' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 30, text: '', type: AnswerType.SelectBox, dependentField: { 27: YesNo.Yes, 29: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C29' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 31, text: 'Vertical Care', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 32, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 31: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 19 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 33, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 31: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C33' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 34, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 31: YesNo.Yes, 33: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C33' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 35, text: 'Immediate Discharge', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 36, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 35: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 20 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 37, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 35: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C37' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 38, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 35: YesNo.Yes, 37: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C37' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 39, text: 'Provider-Only Discharge', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 40, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 39: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 21 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 41, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 39: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C41' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 42, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 39: YesNo.Yes, 41: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C41' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 43, text: 'Early Discharge', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 44, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 43: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 22 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 45, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 43: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C45' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 46, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 43: YesNo.Yes, 45: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C45' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 47, text: 'Discharge Registration', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 48, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 47: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 23 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 49, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 47: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C49' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 50, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 47: YesNo.Yes, 49: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C49' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 51, text: 'Team Discharge', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 52, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 51: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 24 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 53, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 51: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C53' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 54, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 51: YesNo.Yes, 53: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C53' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 55, text: 'Lab Best Practice', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 56, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 55: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 25 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 57, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 55: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C57' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 58, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 55: YesNo.Yes, 57: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C57' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 63, text: 'Urine Collection Process', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 64, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 63: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 26 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 65, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 63: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C61' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 66, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 63: YesNo.Yes, 65: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C61' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 67, text: 'Radiology Best Practice', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 68, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 67: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 27 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 69, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 67: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C65' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 70, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 67: YesNo.Yes, 69: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C65' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 71, text: 'Registration Best Practice', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 72, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 71: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 28 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 73, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 71: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C69' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 74, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 71: YesNo.Yes, 73: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C69' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 75, text: 'Utilization Management', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 76, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 75: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 29 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 77, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 75: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C73' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 78, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 75: YesNo.Yes, 77: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C73' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 79, text: 'Task Reduction/ Task Turnaround Times', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 80, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 79: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 30 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 81, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 79: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C77' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 82, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 79: YesNo.Yes, 81: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C77' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 83, text: 'Ops Improvement Teams', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 84, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 83: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 31 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 85, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 83: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C81' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 86, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 83: YesNo.Yes, 85: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C81' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 87, text: 'Culture', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 88, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 87: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 32 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 89, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 87: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C85' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 90, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 87: YesNo.Yes, 89: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C85' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 91, text: 'Patient Experience', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 92, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 91: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 33 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 93, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 91: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C89' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 94, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 91: YesNo.Yes, 93: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C89' }, targetGroup: 'priorityRecommendationNumber',
            },

            { id: 95, text: 'Psychiatric Intervention ', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, main },
            {
                id: 96, text: 'Include in Established Best Practices', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 95: YesNo.No },
                block: { answer: YesNo.Yes, code: 'A101', blockId: 34 }, targetGroup: 'establishedBestPractice',
            },
            {
                id: 97, text: 'Priority Recommendation', type: AnswerType.RadioGroup, answers: yesNoAnswers, defaultValue: YesNo.No, dependentField: { 95: YesNo.Yes },
                block: { answer: YesNo.Yes, code: 'C93' }, targetGroup: 'priorityRecommendation',
            },
            {
                id: 98, text: 'Priority #', type: AnswerType.SelectBox, dependentField: { 95: YesNo.Yes, 97: YesNo.No },
                block: { answer: ['1', '2', '3', '4'], code: 'C93' }, targetGroup: 'priorityRecommendationNumber',
            },
        ],
        sectionName: SectionName.BestPractices,
        type: QuestionBlockType.Table,
    }
];
