import { useState, useCallback, SetStateAction, Dispatch, useEffect } from 'react';
import { IconButton } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import { TextField } from 'shared/components/TextField';
import { Button, ButtonVariant } from 'shared/components/Button';
import { EventName, appName } from 'shared/constants/appInsights';
import { Popper } from 'shared/components/Popper/Popper';
import { trackEventFx } from 'store/appInsights/effects';

import './FeedbackPopper.scss';

enum State {
    Dislike,
    Like,
}

interface FeedbackPopperProps {
    open: boolean;
    setToggle: () => void;
    pairMessage: { question: string, answer: string } | null;
    id: string;
    anchorEl: HTMLElement | null;
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
    questionText: string;
}

export const FeedbackPopper = ({
    open,
    setToggle,
    pairMessage,
    id,
    anchorEl,
    setAnchorEl,
    questionText,
}: FeedbackPopperProps) => {
    const [feedback, setFeedback] = useState('');
    const [like, setLike] = useState<State>();
    const [error, setError] = useState('');

    const handleClose = useCallback(() => {
        setToggle();
        setAnchorEl(null);
    }, [setAnchorEl, setToggle]);

    const validate = useCallback(() => {
        const hasError = !feedback ? like === State.Dislike : false;

        setError(hasError ? 'For dislike value feedback is required' : '');

        return hasError;
    }, [feedback, like]);

    const sendFeedback = useCallback(() => {
        const hasError = validate();

        if (typeof like !== undefined && !hasError) {
            void trackEventFx({ name: `[${appName}] ${EventName.Feedback} ${like}`, customProperties: {
                ...pairMessage,
                logType: pairMessage !== null ? 'Chat Feedback' : 'General Feedback',
                feedbackText: feedback,
            }});

            handleClose();
        }
    }, [feedback, handleClose, like, pairMessage, validate]);

    useEffect(() => {
        like && validate();
    }, [like, validate]);

    return (
        <Popper
            open={open}
            handleClose={handleClose}
            id={id}
            anchorEl={anchorEl}
        >
            <div className='feedback'>
                <div className='feedback__question'>{questionText}</div>
                <div className='feedback__icons'>
                    <IconButton
                        size='large'
                        onClick={() => setLike(State.Like)}
                    >
                        <SentimentSatisfiedAltIcon
                            color={like === State.Like ? 'success' : undefined}
                            fontSize='large'
                        />
                    </IconButton>
                    <IconButton
                        size='large'
                        onClick={() => setLike(State.Dislike)}
                    >
                        <SentimentVeryDissatisfiedIcon
                            color={like === State.Dislike ? 'error' : undefined}
                            fontSize='large'
                        />
                    </IconButton>
                </div>
                <TextField
                    className='feedback__input'
                    placeholder='Write your feedback'
                    onChange={setFeedback}
                    value={feedback}
                    multiline
                    onBlur={validate}
                    error={!!error}
                    helperText={error}
                />
                <Button
                    onClick={sendFeedback}
                    variant={ButtonVariant.Contained}
                >
                    Send feedback
                </Button>
            </div>
        </Popper>
    );
};
