export enum SingleVariable {
    SiteName = '**Site Name**',
    DateOfAssessment = '**Date of Assessment**',
    DayOfCreatingDocument = '**Day of Creating Document**',
};

export interface Question {
    id: number;
    text: string;
    type: AnswerType;
    defaultValue?: string;
    dependentField?: { [key: number]: string };
    main?: boolean;
    block?: {
        answer: string | string[];
        code: string;
        blockId?: number;
        order?: number;
    };
    changeOrder?: number;
    target?: SingleVariable | string;
    targetChart?: string;
    targetGroup?: string;
    mask?: {
        pattern?: string;
        thousandSeparator?: boolean;
    };
}

export interface RadioBlockQuestion extends Question {
    answers: string[];
}

export interface QuestionBlock {
    id: number;
    stepNumber: number;
    questions: (Question | RadioBlockQuestion)[];
    sectionName: SectionName;
    type: QuestionBlockType;
    blockCode?: string;
}

export enum QuestionBlockType {
    Various = 'various',
    List = 'list',
    Table = 'table',
}

export enum AnswerType {
    Input = 'input',
    RadioGroup = 'radioGroup',
    SelectBox = 'select',
    Date = 'date',
    QuestionsGroup = 'questionsGroup',
}

export enum SectionName {
    GeneralInfo = 'General Info',
    Participants = 'Participants',
    OperationalData = 'Operational Data',
    BestPractices = 'Best Practices',
}
