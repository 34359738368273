import { useState, MouseEvent } from 'react';
import { Button } from '@mui/material';

import { useToggle } from 'shared/utils/hooks/useToggle';
import { ButtonVariant } from 'shared/components';

import { FeedbackPopper } from '../FeedbackPopper/FeedbackPopper';

import './FeedbackButton.scss';

export const FeedbackButton = () => {
    const [open, setToggle] = useToggle(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <div className='feedbackContainer'>
            <Button
                className='feedbackButton'
                variant={ButtonVariant.Contained}
                onClick={(event: MouseEvent<HTMLElement>) => {
                    setToggle();
                    setAnchorEl(anchorEl ? null : event.currentTarget);
                }}
            >
                Feedback
            </Button>
            <FeedbackPopper
                id='simple-popper'
                key={open.toString()}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                open={open}
                setToggle={setToggle}
                pairMessage={null}
                questionText='Share your feedback'
            />
        </div>
    );
};
