import { memo, useCallback } from 'react';
import {
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import cn from 'classnames';

import { TextFieldVariant } from './TextFieldVariant';

import './TextField.scss';

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'onChange'> {
    onChange?: (text: string) => void;
}

export const TextField = memo(({
    variant = TextFieldVariant.Outlined,
    onChange,
    className,
    ...props
}: TextFieldProps) => {
    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        onChange?.(value);
    }, [onChange]);

    return (
        <MuiTextField
            variant={variant}
            onChange={handleChange}
            className={cn('dpdf-text-field', className)}
            {...props}
        />
    );
});
