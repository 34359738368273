const addZero = (value: number) => {
    return value < 10 ? `0${value}` : value;
};

export const getCurrentTimestamp = () => {
    const now = new Date();

    const day = addZero(now.getDate());
    const month = addZero(now.getMonth() + 1);
    const year = now.getFullYear();
    const hours = addZero(now.getHours());
    const minutes = addZero(now.getMinutes());

    return `${month}-${day}-${year}-${hours}-${minutes}`;
};
