import { createEffect } from 'effector';

import { DocumentsAPI } from 'api/document/api';
import { notify, showErrorNotification } from 'shared/utils/notification';
import { Document } from 'models/Document';

import { setHasError, setHasSuccessMessage } from './events';

export const fetchDocuments = createEffect(async (params: {
    assessmentId: number,
    abortController?: AbortController,
} ) => {
    const { assessmentId, abortController } = params;
    const result = await DocumentsAPI.getDocuments(assessmentId, abortController);

    if (!abortController || !abortController.signal.aborted) {
        return result.match(
            data => data!,
            error => {
                showErrorNotification(error);

                return [];
            },
        );
    }
});

export const $areDocumentsLoading = fetchDocuments.pending;

export const fetchDocumentById = createEffect(async (params: { id: string, abortController: AbortController }) => {
    const { id, abortController } = params;
    const result = await DocumentsAPI.getDocumentById(id, abortController);

    if (!abortController.signal.aborted) {
        return result?.match(
            data => data,
            error => {
                setHasError(true);
    
                showErrorNotification(error);
    
                return null;
            },
        );
    }
});

export const $isDocumentFetching = fetchDocumentById.pending;

export const updateDocument = createEffect(async (params: { 
    document: Document,
    successMessage: boolean,
    abortController: AbortController,
}) => {
    const { document, successMessage, abortController } = params;
    const result = await DocumentsAPI.updateDocument(document, abortController);

    if (!abortController.signal.aborted) {
        return result?.match(
            data => {
                if (successMessage) {
                    notify({
                        type: 'success',
                        message: 'The document was successfully updated',
                    });
                }
    
                return data;
            },
            error => {
                showErrorNotification(error);
    
                return null;
            },
        );
    }
});

export const $isUpdatingDocument = updateDocument.pending;

export const createDocument = createEffect(async (params: { document: Document, successMessage: boolean, siteName?: string }) => {
    const { document, successMessage, siteName } = params;
    setHasSuccessMessage(successMessage);

    const result = await DocumentsAPI.createDocument(document, siteName ?? '');

    return result.match(
        result => {
            if (successMessage) {
                notify({
                    type: 'success',
                    message: 'The document was successfully created',
                });
            }

            return result;
        },
        error => {
            showErrorNotification(error);

            return null;
        },
    );
});

export const $isCreatingDocument = createDocument.pending;

export const deleteDocument = createEffect(async (documentId: number) => {
    const result = await DocumentsAPI.deleteDocument(documentId);

    return result.match(
        result => {
            notify({
                type: 'success',
                message: 'The document was successfully deleted',
            });

            return result;
        },
        error => {
            showErrorNotification(error);

            return null;
        },
    );
});
