import { AxiosError } from 'axios';

import { processSuccessfulResponseResult, processUnsuccessfulResponseResult } from 'shared/utils/apiResult';
import { User } from 'models/User/User';

import { USER } from './constants';
import { axiosInstance } from '../axiosInstance';

export const UserAPI = {
    async getUserInfo() {
        const ERR_DESC = 'Failed to get user info';

        try {
            const response = await axiosInstance.get(USER);

            const result = processSuccessfulResponseResult(response, (content: User) => content);

            return result;
        } catch (error) {
            return processUnsuccessfulResponseResult(error as AxiosError, ERR_DESC);
        }
    },
};
