export enum HeadingType {
    Heading1 = 'heading1',
    Heading2 = 'heading2',
    Heading3 = 'heading3',
}

export enum HeadingTypeDisplayName {
    Heading1 = 'Heading 1',
    Heading2 = 'Heading 2',
    Heading3 = 'Heading 3',
}
