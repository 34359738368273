import { Dayjs } from 'dayjs';

export enum YesNo {
    Yes = 'Yes',
    No = 'No',
}

export type UserAnswersValueType = string | number | YesNo | Dayjs | null;

export type RadioGroupValueType = YesNo | null;

export type AnswersBlock = { [key: string]: UserAnswersValueType };

export type UserAnswersSimple = { [key: string]: AnswersBlock };

export type UserAnswersArray = { [key: string]: AnswersBlock[] };

export type UserAnswers = UserAnswersSimple | UserAnswersArray;
