import { ReactNode } from 'react';
import {
    Dialog as MuiDialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import cn from 'classnames';

import { Button, ButtonVariant } from '../Button';

import './Dialog.scss';

interface DialogProps {
    title?: string;
    isOpen: boolean;
    content: ReactNode | string;
    dialogClassName?: string;
    rootClassName?: string;
    onDoneLabel?: string;
    onCancelLabel?: string;
    onProceed: () => void;
    onCancel: () => void;
}

export function Dialog({
    title, isOpen, content, dialogClassName, rootClassName,
    onDoneLabel = 'Done', onCancelLabel = 'Cancel',
    onProceed, onCancel,
}: DialogProps) {
    return (
        <MuiDialog
            open={isOpen}
            onClose={onCancel}
            className={cn('dpdf-dialog', dialogClassName)}
            PaperProps={{ className: 'dpdf-dialog__paper' }}
        >
            {title &&
                <DialogTitle className='dpdf-dialog__title'>
                    {title}
                </DialogTitle>
            }
            <DialogContent classes={{ root: cn('dpdf-dialog__root', rootClassName) }}>
                {content}
            </DialogContent>
            <DialogActions className='dpdf-dialog__actions'>
                <Button
                    variant={ButtonVariant.Outlined}
                    onClick={onCancel} className='dpdf-dialog__cancel-button'
                >
                    {onCancelLabel}
                </Button>
                <Button
                    variant={ButtonVariant.Contained}
                    onClick={onProceed} className='dpdf-dialog__proceed-button'
                >
                    {onDoneLabel}
                </Button>
            </DialogActions>
        </MuiDialog>
    );
}
