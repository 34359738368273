import { forwardRef } from 'react';
import MuiLoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import cn from 'classnames';

import './Button.scss';

export interface ButtonProps extends Omit<LoadingButtonProps, | 'type'> {
    component?: string;
    submit?: boolean;
    textTransform?: boolean;
    buttonClassName?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
    submit = false,
    textTransform,
    buttonClassName,
    ...restProps
}, ref) => {

    return (
        <MuiLoadingButton
            {...restProps}
            ref={ref}
            classes={{
                root: cn(
                    'dpdf-button',
                    { 'dpdf-button_text-transform': textTransform },
                    buttonClassName,
                ),
            }}
            type={submit ? 'submit' : 'button'}
        />
    );
});
