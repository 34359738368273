export interface ImageProperties {
    src: string;
    base64?: string;
    width: number;
    height: number;
    alignment?: Alignment;
}

export enum Alignment {
    Left = 'left',
    Right = 'right',
    Center = 'center',
}

export interface TopLeftCoordinates {
    top: number;
    left: number;
}

export interface Coordinates {
    top: number | string;
    left: number | string;
    bottom?: number | string;
    right?: number | string;
}

export interface Size {
    width: number;
    height: number;
}
