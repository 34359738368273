export const deepCopy = <T>(complexVar: T): T => {
    let copy: unknown;

    if (typeof complexVar !== 'object') {
        copy = complexVar;
    } else {
        if (complexVar === null) {
            copy = null;
        } else if (Array.isArray(complexVar)) {
            copy = [];

            complexVar.forEach(element => {
                (copy as unknown[]).push(typeof element === 'object' ? deepCopy(element) : element);
            });
        } else {
            copy = {};

            Object.entries(complexVar).forEach(([key, value]) => {
                (copy as Record<string, unknown>)[key] = typeof value === 'object' ? deepCopy(value) : value;
            });
        }
    }

    return copy as T;
};
