import { createStore, combine } from 'effector';

import { UserAnswers } from 'models/Questionnaire';

import { steps } from './constants';

export const $stepNumber = createStore<number | null>(null);

export const $steps = createStore(steps);

export const $errorSteps = createStore('');

export const $stepsSuccess = createStore(false);

export const $stepsStatus = combine({
    steps: $steps,
    stepsSuccess: $stepsSuccess,
    errorSteps: $errorSteps
});

export const $userAnswers = createStore<UserAnswers>({});
