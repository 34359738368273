import { lazy } from 'react';

import { Module, Route } from 'models/Module';
import { NotFound } from 'pages/NotFound';

const InitPage = lazy(() => import('pages/InitPage'));
const TemplatesListPage = lazy(() => import('pages/TemplatesListPage'));
const NewTemplate = lazy(() => import('pages/NewTemplate'));
const LibraryPage = lazy(() => import('pages/LibraryPage'));
const Questionnaire = lazy(() => import('pages/Questionnaire'));
const ComponentPage = lazy(() => import('pages/ComponentPage'));
const AssessmentsListPage = lazy(() => import('pages/AssessmentsListPage'));
const AssessmentPage = lazy(() => import('pages/AssessmentPage'));

export const routes: Module[] = [
    { path: Route.AssessmentList, component: AssessmentsListPage },
    { path: Route.Templates, component: TemplatesListPage },
    { path: Route.Template, component: NewTemplate },
    { path: Route.Library, component: LibraryPage },
    { path: Route.Questionnaire, component: Questionnaire },
    { path: Route.Init, component: InitPage },
    { path: Route.Assessment, component: AssessmentPage },
    { path: Route.Component, component: ComponentPage },
    { path: Route.ComponentId, component: ComponentPage },
    { path: Route.NotFound, component: NotFound },
];
