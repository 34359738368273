import { ReactNode, PropsWithChildren } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme } from '@mui/material/styles/createTheme';

import { defaultTheme } from './themes';

interface ThemeProviderProps {
    theme?: Theme;
    children: ReactNode;
}

export const ThemeProvider = ({
    theme = defaultTheme,
    children,
}: PropsWithChildren<ThemeProviderProps>) => (
    <MuiThemeProvider theme={theme}>
        <>
            <CssBaseline />
            {children}
        </>
    </MuiThemeProvider>
);
