import { Size } from 'models/Assessment';

export const getSize = (sizeValue: number) => {
    if (sizeValue >= 20000 && sizeValue < 40000) {
        return Size.Med;
    } else if (sizeValue >= 40000 && sizeValue < 60000) {
        return Size.High;
    } else if (sizeValue >= 60000) {
        return Size.VeryHigh;
    }

    return Size.Low;
};
