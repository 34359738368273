import Resizer from 'react-image-file-resizer';

import { PRINT_RATIO } from 'shared/constants/pdf';

export const resizeFile = (file: File, minWidth: number, minHeight: number, type = 'JPEG'): Promise<File> =>
    new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            minWidth * PRINT_RATIO,
            minHeight * PRINT_RATIO,
            type,
            100,
            0,
            uri => {
                resolve(uri as File);
            },
            'file',
        );
    },
);
