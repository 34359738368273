import { Link } from 'react-router-dom';

import './NotFoundFallback.scss';

export const NotFoundFallback = (): JSX.Element => {
    return (
        <div className='not-found'>
            <div className='not-found__title'>Page does not exist.</div>
            <div className='not-found__link-container'>
                <Link className='not-found__link' to='/'>Go to home page</Link>
            </div>
        </div>
    );
};
