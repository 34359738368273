import {
    Radio as MuiRadio,
    FormControlLabel,
    FormControlLabelProps,
} from '@mui/material';

import './Radio.scss';

export const Radio = (props: Omit<FormControlLabelProps, 'control'>) => (
    <FormControlLabel
        {...props}
        control={<MuiRadio size='small' />} //?
        classes={ { root: 'pdf-checkbox' } }
    />
);
