import { createTheme } from '@mui/material/styles';

export const defaultTheme = createTheme({
    palette: {
        background: {
            default: '#fdffff',
        },
        primary: {
            main: '#0277BD',
        },
        secondary: {
            main: '#0277BD1A',
            contrastText: '#0277BD',
        },
    },
    typography: {
        fontFamily: 'Outfit',
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: '10px 14px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    fontFamily: 'inherit',
                },
            },
        },
    },
});
