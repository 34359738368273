import { Button, ButtonSize, ButtonVariant } from 'shared/components/Button';

import './PageError.scss';

export const PageError = () => {
	const reloadPage = () => {
		location.reload();
	};

	return (
		<div className={'page-error'}>
			<div className='page-error__text'>Sorry, an unexpected error occurred</div>
			<Button
				onClick={reloadPage}
				variant={ButtonVariant.Contained}
				size={ButtonSize.Large}
				className='page-error__button'
			>
				Reload page
			</Button>
		</div>
	);
};
